<template>
  <nav class="navbar top" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item pt-0" href="/">
          <img v-if="logoLight" src="@/assets/logo3-light.svg" />
          <img v-else src="@/assets/logo3.svg" />
        </a>

        <router-link v-if="user" :to="{ name: 'Dashboard' }" class="navbar-item  ml-auto is-hidden-tablet">
          View Dashboard
        </router-link>

        <div v-if="!user" class="navbar-item  ml-auto is-hidden-tablet">
          <button class="button is-light is-outlined is-small" @click="showSignInModal = true">
            Log in
          </button>
        </div>
        <div v-if="!user" class="navbar-item pl-0 is-hidden-tablet">
          <router-link :to="{ name: 'SignUp' }" class="button is-black is-small">
            Sign up
          </router-link>
        </div>

        <!-- <a role="button" class="navbar-burger ml-0" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a> -->
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link :to="{ name: 'Experience' }" class="navbar-item">
            The Experience
          </router-link>
          <router-link :to="{ name: 'Solutions' }" class="navbar-item">
            Solutions
          </router-link>
          <router-link :to="{ name: 'Catalog' }" class="navbar-item">
            Catalog
          </router-link>
        </div>

        <div v-if="user" class="navbar-end">
          <router-link :to="{ name: 'Dashboard' }" class="navbar-item">
              Hi, {{ user.displayName }}
            </router-link>
            <router-link :to="{ name: 'Dashboard' }" class="navbar-item">
              View Dashboard
            </router-link>
        </div>

        <div v-if="!user" class="navbar-end">
          <a class="navbar-item" @click="showSignInModal = true">
            Log in
          </a>
          <router-link :to="{ name: 'SignUp' }" class="navbar-item pl-0">
            Sign up
          </router-link>
        </div>
      </div>
    </div>
  </nav>

  <Teleport to="body">
    <SignInModal
      v-if="showSignInModal"
      @close="showSignInModal = false"
    />
  </Teleport>
</template>

<script>
import { ref } from 'vue'
import getUser from '@/composables/getUser'
import SignInModal from '@/components/Modals/SignInModal.vue'

export default {
  props: ['logoLight'],
  components: { SignInModal },
  setup() {
    const { user } = getUser()

    const showSignInModal = ref(false)

    return {
      user,
      showSignInModal,
    }
  }
}

</script>

<style scoped>
.navbar {
  background: none;
  border: 0 !important;
}
</style>

<style>
.hero.is-info a.navbar-item:hover {
  background: none !important;
}

@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background: none !important;
  }
}
</style>