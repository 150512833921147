<template>
  <Modal
    title="Log In"
    :isSmall="true"
    @close="$emit('close')"
  >
    <FormField
      label="Email"
      type="email"
      v-model="email"
    />

    <FormField
      label="Password"
      type="password"
      v-model="password"
    />

    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>

    <template v-slot:footer>
      <button
        :class="`button is-black ${isPending && 'is-loading'}`"
        :disabled="!email || !password"
        @click="logInEmail()"
      >
        Log in
      </button>
      <router-link :to="{ name: 'ForgotPassword' }" class="button">
        Forgot Password
      </router-link>
    </template>    
  </Modal>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useLogin from '@/composables/useLogin'
import Modal from '@/components/Modals/Modal'
import FormField from '@/components/Forms/FormField'

export default {
  props: ['fromInvite'],
  emits: ['close'],
  components: { Modal, FormField },
  setup(props) {
    const router = useRouter()
    const { error, login, isPending } = useLogin()

    const email = ref('')
    const password = ref('')

    const logInEmail = async () => {
      const res = await login(email.value, password.value)
      console.log('res', res)
      if (error.value) return

      if (!props.fromInvite) {
        router.push({ name: 'Dashboard' })
      }
    }

    return {
      email,
      password,
      logInEmail,
      error,
      isPending
    }   
  }
}
</script>