<template>
  <footer class="section has-text-centered pt-0 pb-5">
    <div class="container">
      <hr />

      <div class="level is-justify-content-flex-start">
        <router-link :to="{ name: 'Contact' }" class="navbar-item">
          Contact
        </router-link>
        <router-link :to="{ name: 'Faqs' }" class="navbar-item">
          Faqs
        </router-link>
        <router-link :to="{ name: 'Terms' }" class="navbar-item">
          Terms+Policies
        </router-link>
        <router-link :to="{ name: 'JoinBeta' }" class="navbar-item ml-auto">
          Join the closed beta
        </router-link>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  overflow: auto;
  background: var(--background);
}

a.navbar-item {
  color: #000;
}
</style>