<template>  
  <section class="hero is-light">
    <div class="hero-head">
      <Navbar />
    </div>

    <div class="hero-body">
      <div class="container">
        <h1 class="subtitle is-size-3 mb-6">
          Our catalog
        </h1>

        <div class="columns is-multiline is-mobile">
          <div v-for="product in products" :key="product.id" class="column is-3-desktop is-4-tablet is-6-mobile">
            <div class="product has-text-centered">
              <figure class="image">
                <img :src="product.images[0].url" />
              </figure>
              <p class="heading mt-4">{{ product.line1 }}</p>
              <h4 class="subtitle">{{ product.line2 }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hero-foot">
      <Footer />
    </div>
  </section>
</template>

<script>
import getCollection from '@/composables/getCollection'
import Navbar from '@/components/Navbar1.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: { Navbar, Footer },
  setup() {
    const { documents: products } = getCollection('products')

    return {
      products,
    }
  }
}
</script>